import React, { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as LinkIcon } from '../../../assets/images/link.svg';
import { ReactComponent as LoadingIcon } from '../../../assets/images/loading.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/trash-red.svg';
import Button from '../../../components/Elements/button/button';
import InputElement from '../../../components/FormElements/input';
import { addToast } from '../../../store/features/toastSlice';
import { uploadMedia } from '../../../store/services/media';
import { ColorPicker } from '../common/color-picker';

export const ImageGeneralConfig = ({ component, setSelectedComponent = () => {} }) => {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const { t } = useTranslation();
  const { name, image, lexicon } = component;
  const [colorPicker, setColorPicker] = useState('');

  const [uploadingImage, setUploadingImage] = useState(false);

  const onImageUpload = async file => {
    if (file) {
      try {
        setUploadingImage(true);
        const imageData = await uploadMedia(file);
        setSelectedComponent({
          ...component,
          image: {
            id: imageData?.media_external_id,
            image: { url: imageData.url },
            name: file.name,
            size: file.size,
            height: image?.height,
            width: image?.width,
          },
        });
        setUploadingImage(false);
        inputRef.current.value = '';
      } catch (error) {
        dispatch(addToast({ error: true, text: 'Error while uploading image' }));
        setUploadingImage(false);
        inputRef.current.value = '';
      }
    }
  };

  return (
    <div className="flex-column my-8">
      <label className="flex-1 medium-text font-16">{t('GENERAL_UC')}</label>
      <InputElement
        name="Name"
        onChange={value => setSelectedComponent({ ...component, name: value })}
        value={name}
        label="Enter"
        className="w-full mt-6"
        font={{ size: 12 }}
        showRequiredError={true}
      />
      <InputElement
        name="Lexicon"
        onChange={value => setSelectedComponent({ ...component, lexicon: value })}
        value={lexicon}
        label="Enter"
        className="w-full mt-6"
        font={{ size: 12 }}
      />
      <ColorPicker
        className="flex items-centers mt-4"
        label={'Color'}
        settingKey={'color'}
        selectedColor={component.component_color}
        onChangeColor={(settingKey, color) => setSelectedComponent({ ...component, component_color: color })}
        colorPicker={colorPicker}
        setColorPicker={setColorPicker}
      />
      <div className="flex-column justify-center mt-4">
        <label className="flex regular-text font-12 flex-1 mb-2">{t('IMAGE')}</label>
        {image?.image?.url ? (
          <div className="flex">
            <div className="flex-column flex-1">
              {image.name && <label className="regular-text">{image.name}</label>}
              {image.size && (
                <label className="regular-text grey-text">{`${parseFloat(parseInt(image.size) / 1024).toFixed(
                  2,
                )} kb`}</label>
              )}
            </div>
            <div
              className="pxy-1 radius-2 flex delete-widget h-fit-content cursor"
              onClick={() =>
                setSelectedComponent({
                  ...component,
                  image: { height: '', width: '' },
                })
              }>
              <DeleteIcon width={14} height={14} />
            </div>
          </div>
        ) : (
          <div className="flex items-center mt-2 col-gap-4">
            <input
              className="display-none"
              type="file"
              ref={inputRef}
              onChange={e => {
                const file = e.target.files[0];
                onImageUpload(file);
              }}
            />
            <Button
              icon={uploadingImage ? <LoadingIcon height={14} /> : <Fragment />}
              label={'Upload image'}
              size="medium"
              borderRadius="12px"
              className="primary-white flex-1"
              onClick={() => !uploadingImage && inputRef.current.click()}
            />
            <label className="flex-1 regular-text font-12">SVG, PNG, JPG or GIF (max. 000x000px)</label>
          </div>
        )}
        {/* <div className="flex items-center mt-4">
          <InputElement
            name="Height"
            onChange={value => setSelectedComponent({ ...component, image: { ...image, height: value } })}
            value={image?.height}
            label="Enter"
            className="w-full flex-1"
            font={{ size: 12 }}
            type={'NUMERIC'}
          />
          <LinkIcon className="mt-5 mx-3" />
          <InputElement
            name="Width"
            onChange={value => setSelectedComponent({ ...component, image: { ...image, width: value } })}
            value={image?.width}
            label="Enter"
            className="w-full flex-1"
            font={{ size: 12 }}
            type={'NUMERIC'}
          />
        </div> */}
      </div>
    </div>
  );
};
