import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Placeholder } from '../assets/images/subtract.svg';
import DropDown from '../components/McDropdown';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import useDebounce from '../helpers/useDebounceHook';
import { createForm, getFormType, updateFormDetails } from '../store/features/formsSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { AddFormWrapper } from './modal.styled';
import { getItemFromLocalStorage } from '../helpers/localstorage';

const RenderFormOption = props => {
  const { option: value, handleSelect, index } = props;
  return (
    <div className="flex-column items-start w-full">
      <label
        className={`medium-text grey-text font-14 flex option-name w-full py-2 px-2 ${index > 0 ? 'border-top' : ''}`}>
        {value.name}
      </label>
      {value?.options.map(item => (
        <div
          key={item.id}
          className="flex items-center px-2 cursor w-full mc-option-item"
          onClick={() => handleSelect(item)}
          style={{ height: `40px` }}>
          <label className="regular-text flex option-name">{item.name}</label>
        </div>
      ))}
    </div>
  );
};

const AddForm = () => {
  const { setModal, modal } = useContext(OrganisationContext);
  const { onSuccess = () => {}, updateForm } = modal.content;
  const dispatch = useDispatch();
  const prizedrawPageRef = useRef(0);

  const { formType, formList } = useSelector(state => state.forms);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(updateForm);
  const [error, setError] = useState({});
  const [prizeDrawText, setPrizeDrawText] = useState('');
  const debouncedPrizeDrawSearch = useDebounce(prizeDrawText, 500);
  const [clientText, setClientText] = useState('');
  const debouncedClientSearch = useDebounce(clientText, 500);
  const [labelText, setLabelText] = useState('');
  const debouncedLabelText = useDebounce(labelText, 500);
  const [lstFormType, setFormType] = useState([]);

  useEffect(() => {
    const customOptions = (formType || []).filter(item => item.category === 'CUSTOM') || [];
    const defaultOptions = (formType || []).filter(item => item.category !== 'CUSTOM') || [];
    setFormType([
      { name: 'DEFAULT', options: [...defaultOptions] },
      { name: 'CUSTOM', options: [...customOptions] },
    ]);
  }, [formType]);

  const checkFormErrors = () => {
    if (!form?.name?.trim() || !form?.description?.trim()) {
      setError({
        name: !form?.name?.trim(),
        description: !form?.description?.trim(),
      });
      dispatch(addToast({ error: true, text: 'Please fill all required fields' }));
      return true;
    }
    return false;
  };

  const onConfirmAction = async () => {
    if (loading || checkFormErrors()) {
      return;
    }
    const localUser = getItemFromLocalStorage('user');
    const orgId = localUser.organizations?.[0]?.id;
    setLoading(true);
    const request = {
      name: form?.name,
      description: form?.description,
      organizations: [orgId],
    };

    if (updateForm) {
      dispatch(updateFormDetails({ id: form?.id, request: { name: form?.name, description: form?.description } }))
        .then(response => {
          if (onSuccess)
            onSuccess(
              dispatch(addToast({ error: false, title: 'Update Form', text: 'form has been successfully updated ' })),
            );

          setModal(initModal);
        })
        .catch(err => {
          dispatch(addToast({ error: true, text: 'form has been not updated' }));
          console.log(err);
        });
    } else {
      dispatch(createForm({ request: { ...request }, pageNumber: formList?.total_pages - 1 }))
        .then(response => {
          if (onSuccess)
            onSuccess(
              dispatch(addToast({ error: false, title: 'Add Form', text: 'form has been successfully created ' })),
            );

          setModal(initModal);
        })
        .catch(err => {
          dispatch(addToast({ error: true, text: 'form has been not create' }));
          console.log(err);
        });
    }
  };

  return (
    <div>
      <CommonPopup
        popupTitle={updateForm ? 'Update Form' : `Add Form`}
        confirmButtonProps={{ label: updateForm ? 'Update' : 'Add' }}
        disabled={loading}
        onCancel={() => setModal(initModal)}
        onConfirm={onConfirmAction}>
        <AddFormWrapper className="flex-column items-center w-full">
          <div className="w-full flex-column mt-6">
            <label className="regular-text lighter-text mb-1 font-12">{t('NAME')}</label>
            <input
              autoComplete="turnoff"
              className={`input ${error?.name && 'error-border'}`}
              onChange={e => {
                setForm({ ...form, name: e.target.value });
                setError({ ...error, name: false });
              }}
              placeholder="Form name"
              value={form?.name || ''}
            />
          </div>
          <div className="w-full flex-column mt-6">
            <label className="regular-text lighter-text mb-1 font-12">{t('DESCRIPTION')}</label>
            <textarea
              className={`input description-input ${error?.description && 'error-border'}`}
              onChange={e => {
                setForm({ ...form, description: e.target.value });
                setError({ ...error, description: false });
              }}
              placeholder="Enter description"
              rows={4}
              value={form?.description || ''}
            />
          </div>
          {/* <div className="w-full flex-column mt-6">
            <label className="regular-text lighter-text mb-1 font-12">{t('LABEL')}</label>
            <DropDown
              className={`label-selector ${error.label && 'error-border'}`}
              options={lstFormType}
              selected={form?.label}
              setSelected={o => {
                setForm({ ...form, label: o });
                setLabelText('');
                setError({ ...error, label: false });
              }}
              placeholder={`Select`}
              isSearchable={true}
              search={labelText}
              setSearch={setLabelText}
              RenderOption={props => <RenderFormOption {...props} form={form} />}
              menuHeight={(formType?.length < 4 ? formType?.length : 4) * 40}
            />
          </div> */}
        </AddFormWrapper>
      </CommonPopup>
    </div>
  );
};

export default AddForm;
